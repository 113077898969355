import axios from 'axios';

export async function DeletarDados(id) {
    // URL da API fictícia
    const apiUrl = `https://icnvback.onrender.com/delete/${id}`;
    const apiUrl_imagem = `https://icnvback.onrender.com/delete_imagem_id/${id}`;
  
    // Fazendo uma solicitação DELETE usando Axios e retornando a Promise
    console.log('deletar...', typeof id, id);
    try {
        const response = await axios.delete(apiUrl);
        // Manipule os dados da resposta aqui
        const data = response.data;
        return data;
    } catch (error) {
        // Trate os erros de solicitação ou resposta
        console.error(error);
        throw error; // Lança o erro novamente para tratamento posterior, se necessário
    }
}

