// Importe o Axios
import axios from 'axios';

export async function AtualizarDados(dados) {
    // URL da API fictícia
    //const apiUrl = 'https://backend-cadigreja.onrender.com/inserir';
    const apiUrl = 'https://icnvback.onrender.com/update';
    //const apiUrl = 'https://jsonplaceholder.typicode.com/posts';
  
    // Fazendo uma solicitação GET usando Axios e retornando a Promise
    return await axios.put(apiUrl, dados)
  
    // variavel resposta retorna a mensagem de validação da requisição api, se foi inserido ou não e o porque.
    .then(resposta => {
      return resposta.data;
    })
      .then(response => {
        // Manipule os dados da resposta aqui
        const data = response;     
        return data;
      })
      .catch(error => {
        // Trate os erros de solicitação ou resposta
        console.error(error);
        throw error; // Lança o erro novamente para tratamento posterior, se necessário
      });
  }