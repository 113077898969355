<script>
import { ConectarApi } from '../APIconection/api_connection';
import { obterImagemDaAPI } from '../APIconection/getimagemid';
import { Buscar_Aniversariantes } from '../APIconection/aniversariantes';
import { Todos_os_Homens } from '../APIconection/homens';
import { Todas_as_mulheres } from '../APIconection/mulheres';
import { Faixaetaria } from '../APIconection/faixaEtaria';
import { AtualizarDados } from '../APIconection/atualizardados';
import { DeletarDados } from '../APIconection/deletar_pessoa';
import { Deletarimagem } from '../APIconection/deletar_imagem';
import { enviarParaAPI } from '../APIconection/postImagem';
import rodape from '/src/components/rodape.vue';
import Header from '/src/components/Header.vue';

export default {
    name: 'Pessoas',

    components: {
        Header,
        rodape
    },
    data() {
        return {
            dados_api: [],
            dados_api_pesquisa: [],
            acesso: false,
            viewpessoa: false,
            login: '',
            senha: '',
            item_pessoa: '',
            imagem: '',
            msg_login: '',
            filtro: false,
            niver: false,
            msg_niver: '',
            num_pessoas: '',
            controle_faixa: false,
            controle_homens: false,
            controle_mulheres: false,
            valor1: '',
            valor2: '',
            pesquisar: '',
            atualizar: false,
            resposta_atualizacao: '',
            adm: false,
            post_imagem: '',
            aniversariante: [],
            btn_ativar: 0

        };
    },
    methods: {
        async iniciar() {
            try {
                // Realiza a primeira requisição
                const data = await ConectarApi();

                if (data.status_code == 200) {
                    this.dados_api = data.body;
                    this.num_pessoas = data.body.length
                    this.dados_api = this.ordenarPorNome(this.dados_api)
                    this.dados_api_pesquisa = this.ordenarPorNome(this.dados_api)
                }
            } catch (error) {
                console.error('Erro ao iniciar:', error);
            }
        },
        ordenarPorNome(lista) {
            lista.sort((a, b) => {
                let nomeA = a.nome.toUpperCase(); // converta os nomes para maiúsculas para comparação
                let nomeB = b.nome.toUpperCase();
                if (nomeA < nomeB) {
                    return -1;
                }
                if (nomeA > nomeB) {
                    return 1;
                }
                return 0;
            });
            return lista;
        },

        ordenarPorDia(lista) {
            lista.sort((a, b) => {
                // Verifique se ambos os valores de dataNascimento existem
                if (!a.data_nascimento || !b.data_nascimento) {
                    return 0; // não altera a ordem se um deles estiver faltando
                }

                // Extrai o dia (dois primeiros dígitos) no formato DDMMYYYY
                let diaA = parseInt(a.data_nascimento.slice(0, 2), 10);
                let diaB = parseInt(b.data_nascimento.slice(0, 2), 10);

                return diaA - diaB;
            });
            return lista;
        },

        async aniversariantes(id) {
            try {
                // Realiza a primeira requisição
                const data = await Buscar_Aniversariantes(id);

                this.btn_ativar = id

                if (data.status_code == 200) {
                    this.dados_api = data.body;

                    this.dados_api = this.ordenarPorDia(this.dados_api);

                    this.num_pessoas = data.body.length;

                } else if (data.status_code == 400) {
                    this.num_pessoas = 0;
                    this.dados_api = '';
                }

                else {
                    this.dados_api = '';
                }

            } catch (error) {
                console.error('Erro ao iniciar:', error);
            }
        }
        ,
        aniversariantedodia() {

            this.aniversariante = []

            // Obtém a data atual
            var dataAtual = new Date();

            // Extrai o dia e o mês da data atual
            var dia = dataAtual.getDate();
            var mes = dataAtual.getMonth() + 1; // Mês é baseado em zero, então somamos 1

            // varrendo a lista dados_api
            for (var i = 0; i < this.dados_api.length; i++) {

                const aniversariante_dia = this.dados_api[i].data_nascimento.substring(0, 2)
                const aniversariante_mes = this.dados_api[i].data_nascimento.substring(2, 4)

                var int_mes = parseInt(aniversariante_mes)
                var int_dia = parseInt(aniversariante_dia)

                if (mes === int_mes) {

                    if (dia === int_dia) {

                        // adicionando a pessoa que faz aniversario no dia.
                        this.aniversariante.push(this.dados_api[i])

                    }

                }
            }

        },
        async Todos_homens() {
            try {
                // Realiza a primeira requisição
                const data = await Todos_os_Homens();

                if (data.status_code == 200) {
                    this.dados_api = data.body;
                    this.dados_api = this.ordenarPorNome(this.dados_api)
                    this.num_pessoas = data.body.length

                }
                else {
                    this.dados_api = ''
                }

            } catch (error) {
                console.error('Erro ao iniciar:', error);
            }
        },
        async Todas_mulheres() {
            try {
                // Realiza a primeira requisição
                const data = await Todas_as_mulheres();

                if (data.status_code == 200) {
                    this.dados_api = data.body;
                    this.dados_api = this.ordenarPorNome(this.dados_api)
                    this.num_pessoas = data.body.length

                }
                else {
                    this.dados_api = ''
                }

            } catch (error) {
                console.error('Erro ao iniciar:', error);
            }
        },
        async Faixa_etaria(valor1, valor2) {

            try {
                // Realiza a primeira requisição
                const data = await Faixaetaria(valor1, valor2);

                if (data.status_code == 200) {
                    this.dados_api = data.body;
                    this.dados_api = this.ordenarPorNome(this.dados_api)
                    this.num_pessoas = data.body.length

                }
                else if (data.status_code == 400) {
                    this.num_pessoas = 0
                    this.dados_api = ''
                }
                else {
                    this.dados_api = ''
                }

            } catch (error) {
                console.error('Erro ao iniciar:', error);

            }
        },
        formatarTel(variavel) {
            // Verificar se a variável tem exatamente 11 dígitos
            if (typeof variavel === 'string' && variavel.length === 11) {
                // Extrair os componentes do telefone
                const codigo = variavel.substring(0, 2);
                const telefone = variavel.substring(2);

                // Formatar o telefone
                const telFormatado = `${codigo} ${telefone}`;

                return telFormatado;
            } else {
                // Caso a variável não tenha 11 dígitos
                //console.error('A variável deve ter exatamente 11 dígitos.');
                return null;
            }
        },

        formatarData(variavel) {
            // Verificar se a variável tem exatamente 8 dígitos
            if (typeof variavel === 'string' && variavel.length === 8) {
                // Extrair os componentes da data
                const dia = variavel.substring(0, 2);
                const mes = variavel.substring(2, 4);
                const ano = variavel.substring(4);

                // Formatar a data
                const dataFormatada = `${dia}/${mes}/${ano}`;

                return dataFormatada;
            } else {
                // Caso a variável não tenha 8 dígitos
                //console.error('A variável deve ter exatamente 8 dígitos.');
                return null;
            }
        },

        abrirpessoa(item) {
            this.item_pessoa = item;
        },

        sair() {
            this.acesso = false;
            this.login = '';
            this.senha = '';
            this.msg_login = '';
            this.niver = false
            this.msg_niver = ''
            this.filtro = false
            this.controle_homens = false
            this.controle_mulheres = false
            this.controle_faixa = false
            this.valor1 = ''
            this.valor2 = ''
            this.resposta_atualizacao = ''
            this.adm = false
            this.iniciar()
        },

        efetuarLogin() {
            const usuarioEsperado = 'icnvararuama';
            const senhaEsperada = '123456';
            const usuarioEsperado2 = 'Administrador1'
            const senhaEsperada2 = '102030'

            if (this.login === usuarioEsperado && this.senha === senhaEsperada || this.login === usuarioEsperado2 && this.senha === senhaEsperada2) {
                this.acesso = true;
                this.viewpessoa = false;
                this.msg_login = '';

                if (this.login === usuarioEsperado2) {
                    this.adm = true
                }

            } else if (this.login === usuarioEsperado && this.senha !== senhaEsperada) {
                this.msg_login = 'Senha Incorreta... Tente outra vez!';
            } else if (this.login !== usuarioEsperado && this.senha === senhaEsperada) {
                this.msg_login = 'Login Incorreto... Tente outra vez!';
            } else {
                this.msg_login = 'Não foi possível realizar o Login.';
            }
        },

        async obterImagem(id) {
            try {
                // Use a função e manipule a URL da imagem como quiser
                const url = await obterImagemDaAPI(id);
                this.imagem = url;
                // Faça o que precisar com a URL da imagem, como exibir em uma tag <img>
            } catch (error) {
                console.error('Erro ao obter imagem:', error);
            }
        },
        ativar_filtro() {
            this.pesquisar = ''
            this.filtro = !this.filtro
            if (this.filtro == false) {
                this.niver = false
                this.msg_niver = ''
                this.controle_homens = false
                this.controle_mulheres = false
                this.controle_faixa = false
                this.valor1 = ''
                this.valor2 = ''
                this.pesquisar = ''
                this.iniciar()
            }

        },
        ativar_niver() {
            this.niver = !this.niver
            if (this.niver == false) {
                this.msg_niver = ''
                this.btn_ativar = 0
                this.iniciar()
            }
        },
        ativar_faixa() {
            this.controle_faixa = !this.controle_faixa
            if (this.controle_faixa == false) {
                this.valor1 = ''
                this.valor2 = ''
                this.iniciar()
            }
        },
        ativar_homens() {

            this.controle_homens = !this.controle_homens

            if (this.controle_homens == true) {
                this.Todos_homens()
            }
            else {
                this.iniciar()
            }
        },
        ativar_mulheres() {

            this.controle_mulheres = !this.controle_mulheres

            if (this.controle_mulheres == true) {
                this.Todas_mulheres()
            }
            else {
                this.iniciar()
            }
        },
        mensagem_niver(num_mes) {

            if (num_mes == 1) {
                this.msg_niver = 'Janeiro'
            }
            else if (num_mes == 2) {
                this.msg_niver = 'Fevereiro'
            }
            else if (num_mes == 3) {
                this.msg_niver = 'Março'
            }
            else if (num_mes == 4) {
                this.msg_niver = 'Abril'
            }
            else if (num_mes == 5) {
                this.msg_niver = 'Maio'
            }
            else if (num_mes == 6) {
                this.msg_niver = 'Junho'
            }
            else if (num_mes == 7) {
                this.msg_niver = 'Julho'
            }
            else if (num_mes == 8) {
                this.msg_niver = 'Agosto'
            }
            else if (num_mes == 9) {
                this.msg_niver = 'Setembro'
            }
            else if (num_mes == 10) {
                this.msg_niver = 'Outubro'
            }
            else if (num_mes == 11) {
                this.msg_niver = 'Novembro'
            }
            else if (num_mes == 12) {
                this.msg_niver = 'Dezembro'
            }
        },

        atualizando(item_pessoa) {

            this.atualizar = !this.atualizar
            this.acesso = true

        },

        salvar_atualizacao(id) {

            // Recupera o elemento pelo ID
            var nome = document.getElementById('nome');
            var data_nascimento = document.getElementById('data_nascimento');
            var sexo = document.getElementById('sexo');
            var email = document.getElementById('email');
            var telefone = document.getElementById('telefone');
            var estado = document.getElementById('estado');
            var cidade = document.getElementById('cidade');
            var bairro = document.getElementById('bairro');
            var logradouro = document.getElementById('logradouro');
            var numero = document.getElementById('numero');
            var complemento = document.getElementById('complemento');


            // obtemdo os valores
            nome = nome.value;
            data_nascimento = data_nascimento.value;
            sexo = sexo.value;
            email = email.value;
            telefone = telefone.value;
            estado = estado.value;
            cidade = cidade.value;
            bairro = bairro.value;
            logradouro = logradouro.value;
            numero = numero.value;
            complemento = complemento.value


            // tranformando em valores str e int os parametros em htmlelement

            id = parseInt(id);
            nome = nome.toString();
            data_nascimento.toString();
            sexo = sexo.toString();
            email = email.toString();
            telefone = telefone.toString();
            estado = estado.toString();
            cidade = cidade.toString();
            bairro = bairro.toString();
            logradouro = logradouro.toString();
            numero = numero.toString();
            complemento = complemento.toString();



            const lista_dados_atualizar = {
                'id': id,
                'nome': nome,
                'data_nascimento': data_nascimento,
                'sexo': sexo,
                'email': email,
                'telefone': telefone,
                'estado': estado,
                'cidade': cidade,
                'bairro': bairro,
                'logradouro': logradouro,
                'numero': numero,
                'complemento': complemento,
                'status': true
            }


            this.atualizar_no_banco(lista_dados_atualizar)

            console.log('Salvando dados...', lista_dados_atualizar, 'id = ', id, this.resposta_atualizacao)



            this.atualizar = false
            this.abrirpessoa(lista_dados_atualizar)
            this.iniciar()

        },
        async atualizar_no_banco(dadosParaEnviar) {


            try {
                // formatando dados para o formato JSON.
                const dadosJson = JSON.stringify(dadosParaEnviar);

                // realizando a requisição POST com os dados enviados.
                const respostapost = await AtualizarDados(dadosParaEnviar);

                // função com a mensagem de confirmação
                this.mensagem_confirm(2)

                // retornando a resposta da requisição.
                return respostapost;

            } catch (error) {
                // Trata os erros para ambas as requisições aqui
                console.error('Erro:', error);
            }
        },
        async deletar_no_banco(item) {

            var resposta = confirm('Tem certeza que deseja excluir esta pessoa? ')

            if (resposta) {

                const id = item.id

                try {
                    // formatando dados para o formato JSON.
                    //const dadosJson = JSON.stringify(dadosParaEnviar);

                    // realizando a requisição POST com os dados enviados.
                    const respostapost = await DeletarDados(id);
                    const respostadelete_imagem = await Deletarimagem(id);
                    this.resposta_atualizacao = respostapost.data
                    this.iniciar()
                    this.mensagem_confirm(3)
                    this.obterImagem(id)

                    // retornando a resposta da requisição.
                    return respostapost, respostadelete_imagem;

                } catch (error) {
                    // Trata os erros para ambas as requisições aqui
                    console.error('Erro:', error);
                }

                this.iniciar()
                this.viewpessoa = false
            }
        },
        async deletar_imagem(item) {

            var resposta = confirm('Você tem certeza que deseja excluir esta imagem? ')

            if (resposta) {

                const id = item.id


                try {

                    // realizando a requisição POST com os dados enviados.
                    const respostapost = await Deletarimagem(id);

                    // this.resposta_atualizacao = respostapost.data

                    this.iniciar()
                    this.imagem = 'null'
                    this.mensagem_confirm(3)


                    // retornando a resposta da requisição.
                    return respostapost;

                } catch (error) {
                    // Trata os erros para ambas as requisições aqui
                    console.error('Erro:', error);
                }

                this.iniciar()
            }
        },
        // Função chamada quando o usuário seleciona uma imagem
        carregarImagem(event) {
            const fileInput = event.target;

            if (fileInput.files.length > 0) {

                // Obtém a primeira imagem do array de arquivos
                const imagem = fileInput.files[0];
                this.post_imagem = imagem
                this.imagem = URL.createObjectURL(imagem)


            }
        },
        inserir_imagem(item) {
            try {

                this.imagem = URL.createObjectURL(this.post_imagem)

                const respostaimagem = enviarParaAPI(this.post_imagem, item.nome, item.id)
                this.post_imagem = ''

                const resposta = this.atualizando_dados(item.id)
                this.viewpessoa = true
                this.atualizar = false
                this.iniciar()
                this.abrirpessoa(item)
                this.mensagem_confirm(4)




            } catch (erro) {
                console.log('não foi possivel enviar dados para imagem post.')
            }

        },
        atualizando_dados(id) {

            this.iniciar()

            for (const key in this.dados_api) {
                const item = this.dados_api[key];
                if (item.id == id) {
                    return item;
                }
            }

        },

        alerta() {

            const lista_achados = []

            if (this.pesquisar != '') {

                for (let i of this.dados_api_pesquisa) {

                    if (i.nome.toLowerCase().includes(this.pesquisar.toLowerCase())) {

                        lista_achados.push(i)
                    }

                }
                this.dados_api = lista_achados
                this.num_pessoas = lista_achados.length
            }

        },
        mensagem_confirm(num) {

            if (num == 1) {
                alert('Imagem deletada com Sucesso!!')
            }
            if (num == 2) {
                alert('Os Dados Foram Atualizados com Sucesso!!')
            }
            if (num == 3) {
                alert('Os Dados Foram Deletados com Sucesso!!')
            }
            if (num == 4) {
                alert('Imagem Inserida Com Sucesso!!')
            }
        },
        restaurar() {
            this.pesquisar = ''
            this.dados_api = this.iniciar()
            this.num_pessoas = this.dados_api.length
        },



    },
    mounted() {
        this.iniciar();
    },

};


</script>


<template>
    <div class="corpo">

        <div v-show="acesso == false">
            <Header></Header>
        </div>

        <div class="imgDiv" v-show="acesso == true">


            <img class="menu" src="../assets/icones/269dd16fa1f5ff51accd09e7e1602267.png"
                v-show="acesso == true && viewpessoa == false" @click="ativar_filtro">



            <img class="imagem_menu" src="../assets/imagens/logo-portal-nova-vida.png" alt="">


            <button class="menu_sair" @click="sair" v-show="acesso == true && viewpessoa == false">Sair</button>


        </div>

        <p class="titulo">CONTROLE DE MEMBROS</p>


        <div class="filtros" v-show="acesso == true && viewpessoa == false && filtro == true">
            <div class="grupo">

                <div class="btn"
                    v-show="controle_faixa == false && controle_homens == false && controle_mulheres == false">
                    <p @click="ativar_niver()">Aniversariantes</p>
                </div>

                <div class="mesbox" v-show="niver == true">
                    <p class="titulo_mes" v-show="msg_niver">Mês de {{ msg_niver }}</p>
                    <p class="titulo_mes" v-show="!msg_niver">Escolha um Mês</p>
                    <div>
                        <button class="meses" v-show="niver" @click="aniversariantes(1) && mensagem_niver(1)"
                            :class="{ niver_btn_ativar: btn_ativar === 1 }">01</button>
                        <button class="meses" v-show="niver" @click="aniversariantes(2) && mensagem_niver(2)"
                            :class="{ niver_btn_ativar: btn_ativar === 2 }">02</button>
                        <button class="meses" v-show="niver" @click="aniversariantes(3) && mensagem_niver(3)"
                            :class="{ niver_btn_ativar: btn_ativar === 3 }">03</button>
                        <button class="meses" v-show="niver" @click="aniversariantes(4) && mensagem_niver(4)"
                            :class="{ niver_btn_ativar: btn_ativar === 4 }">04</button>
                    </div>
                    <div>
                        <button class="meses" v-show="niver" @click="aniversariantes(5) && mensagem_niver(5)"
                            :class="{ niver_btn_ativar: btn_ativar === 5 }">05</button>
                        <button class="meses" v-show="niver" @click="aniversariantes(6) && mensagem_niver(6)"
                            :class="{ niver_btn_ativar: btn_ativar === 6 }">06</button>
                        <button class="meses" v-show="niver" @click="aniversariantes(7) && mensagem_niver(7)"
                            :class="{ niver_btn_ativar: btn_ativar === 7 }">07</button>
                        <button class="meses" v-show="niver" @click="aniversariantes(8) && mensagem_niver(8)"
                            :class="{ niver_btn_ativar: btn_ativar === 8 }">08</button>
                    </div>
                    <div>
                        <button class="meses" v-show="niver" @click="aniversariantes(9) && mensagem_niver(9)"
                            :class="{ niver_btn_ativar: btn_ativar === 9 }">09</button>
                        <button class="meses" v-show="niver" @click="aniversariantes(10) && mensagem_niver(10)"
                            :class="{ niver_btn_ativar: btn_ativar === 10 }">10</button>
                        <button class="meses" v-show="niver" @click="aniversariantes(11) && mensagem_niver(11)"
                            :class="{ niver_btn_ativar: btn_ativar === 11 }">11</button>
                        <button class="meses" v-show="niver" @click="aniversariantes(12) && mensagem_niver(12)"
                            :class="{ niver_btn_ativar: btn_ativar === 12 }">12</button>
                    </div>
                </div>

                <div class="btn" v-show="niver == false && controle_homens == false && controle_mulheres == false"
                    @click="ativar_faixa()">
                    Faixa Etária
                </div>

                <div class="faixa_total" v-show="controle_faixa == true">

                    <p class="titulo_faixa" v-show="controle_faixa == true">Digite a Faixa Etária Desejada</p>

                    <div class="faixa" v-show="controle_faixa == true">

                        <div class="inputs">
                            <p class="titulo_faixa">Mínimo</p>
                            <input type="number" v-model="valor1" class="input_faixa">
                        </div>

                        <div class="inputs">
                            <p class="titulo_faixa">Máximo</p>
                            <input type="number" v-model="valor2" class="input_faixa">
                        </div>

                    </div>

                    <div class="btn_faixa" v-show="controle_faixa == true">
                        <button class="btn_faixa" @click="Faixa_etaria(valor1, valor2)">Enviar</button>
                    </div>
                </div>


                <div class="btn" v-show="niver == false && controle_faixa == false && controle_mulheres == false"
                    @click="ativar_homens()">
                    Homens
                </div>
                <div class="btn" v-show="niver == false && controle_faixa == false && controle_homens == false"
                    @click="ativar_mulheres()">
                    Mulheres
                </div>

                <RouterLink class="router" to="/cadastro">
                    <div class="btn"
                        v-show="niver == false && controle_faixa == false && controle_homens == false && controle_mulheres == false">
                        Novo Cadastro
                    </div>
                </RouterLink>


            </div>

        </div>




        <div class="section">

            <form class="form_login" @submit.prevent="efetuarLogin" v-show="acesso == false">


                <div>
                    <h2 class="titulo">Efetue o Login:</h2>
                </div>

                <h2 class="msg_erro" v-show="msg_login">{{ this.msg_login }}</h2>


                <div class="dadospessoais">
                    <div class="dados">
                        <label for="login">Login</label>
                        <input class="input" v-model="login" type="text" placeholder="Login" id="login" required>

                        <label for="nome">Senha</label>
                        <input class="input" v-model="senha" type="password" placeholder="senha" id="senha" required>
                    </div>
                </div>

                <button class="entrar" type="submit">Entrar</button>

            </form>



            <ul v-show="acesso == true && viewpessoa == false">

                <div class="titulo3">


                    <div class="aniversariantes" :="aniversariantedodia()"
                        v-show="aniversariante[0] && acesso == true && niver == false && pesquisar == false">

                        <p class="titulo_niver">Aniversariantes de Hoje</p>

                        <p class="niverdodia" v-for="item in aniversariante" :key="item.id"
                            @click="abrirpessoa(item), viewpessoa = true, obterImagem(item.id)">

                            {{ item.nome }} está fazendo {{ item.idade }} Anos.
                        </p>

                    </div>



                    <p>Pesquisar Pessoas</p>

                    <form class="form_pesquisar" @submit.prevent="alerta">

                        <input type="text" class="input_pesquisar" placeholder="Insira o nome ou algumas letras"
                            v-model="pesquisar">
                        <button class="btn_pesquisar">OK</button>
                        <button class="btn_pesquisar2" @click="restaurar()">Restaurar</button>

                    </form>
                </div>
                <div class="titulo2">

                    <p class="numpessoas" v-show="acesso == true && viewpessoa == false"> Total de {{ num_pessoas }}
                        Pessoas
                    </p>

                </div>

                <div class="subs">
                    <div class="subnome">
                        Nome
                    </div>
                    <div class="subidade">
                        Idade
                    </div>
                    <div class="subtelefone">
                        Telefone
                    </div>
                    <div class="subdata">
                        Nasc
                    </div>


                </div>


                <li v-for="item in dados_api" :key="item.id"
                    @click="abrirpessoa(item), viewpessoa = true, obterImagem(item.id)">
                    <div class="card">
                        <div class="nome">{{ item.nome }}</div>
                        <div class="idade">{{ item.idade }} </div>
                        <div class="telefone">{{ formatarTel(item.telefone) }}</div>
                        <div class="data">{{ formatarData(item.data_nascimento) }}</div>
                    </div>
                </li>

            </ul>


            <div class="pessoa" v-show="viewpessoa == true">

                <div class="subtitulo">
                    Dados Pessoais
                </div>

                <div class="image">

                    <div class="local_imagem">

                        <input class="input" type="file" id="imagem" accept="image/*" ref="fileInput"
                            @change="carregarImagem" required v-show="atualizar == true && imagem == 'null'">

                        <img class="foto" :src="imagem" alt="">

                        <p class="carregando" v-show="imagem == ''">Carregando Imagem...</p>
                        <p class="carregando" v-show="imagem == 'null'">Sem Imagem.</p>

                        <div class="btns_imagens" v-show="atualizar == true">
                            <button class="adicionar_imagem" @click="inserir_imagem(item_pessoa)">Adicionar</button>

                            <button class="remover_imagem" @click="deletar_imagem(item_pessoa)">Remover Imagem</button>
                        </div>

                    </div>

                    <div class="text_nome" v-show="atualizar == false">

                        <div class="borda"></div>

                        <p class="nome_titulo">{{ item_pessoa.nome }}
                        </p>
                        <div class="borda"></div>

                    </div>


                    <div class="text_nome" v-show="atualizar == true">

                        <div class="borda"></div>

                        <p class="nome_titulo">
                            <label class="input_atualizar">Nome:</label>
                            <input id="nome" class="input_atualizar" :value="item_pessoa.nome">
                        </p>

                        <div class="borda"></div>

                    </div>

                </div>

                <div v-show="atualizar == false">


                    <div class="text">
                        <h4 class="dados_pessoa">
                            Data de Nascimento:
                        </h4>
                        <p>
                            {{ formatarData(item_pessoa.data_nascimento) }}
                        </p>
                    </div>
                    <div class="text">
                        <h4 class="dados_pessoa">
                            Idade:
                        </h4>
                        <p>
                            {{ item_pessoa.idade }}
                        </p>
                        <h4 class="dados_pessoa">
                            Sexo:
                        </h4>
                        <p>
                            {{ item_pessoa.sexo }}
                        </p>
                    </div>


                    <div class="text">
                        <h4 class="dados_pessoa">
                            Email:
                        </h4>
                        <p>
                            {{ item_pessoa.email }}
                        </p>
                    </div>

                    <div class="text">
                        <h4 class="dados_pessoa">
                            Telefone:
                        </h4>
                        <p>
                            {{ formatarTel(item_pessoa.telefone) }}
                        </p>
                    </div>

                    <div class="status">
                        <h4>Status:</h4>
                        <div v-show="item_pessoa.status == true">Ativo</div>
                        <div v-show="item_pessoa.status == false">Inativo</div>
                    </div>
                </div>


                <div v-show="atualizar == true">
                    <div class="text">
                        <h4 class="dados_pessoa">
                            Data de Nascimento:
                        </h4>
                        <p>
                            <input id="data_nascimento" class="input_atualizar" :value="item_pessoa.data_nascimento">
                        </p>
                    </div>
                    <div class="text">
                        <h4 class="dados_pessoa">
                            Idade:
                        </h4>
                        <p>
                            {{ item_pessoa.idade }}
                        </p>
                        <h4 class="dados_pessoa">
                            Sexo:
                        </h4>
                        <p>
                            <input id="sexo" class="input_atualizar" :value="item_pessoa.sexo">
                        </p>
                    </div>


                    <div class="text">
                        <h4 class="dados_pessoa">
                            Email:
                        </h4>
                        <p>
                            <input id="email" class="input_atualizar" :value="item_pessoa.email">
                        </p>
                    </div>

                    <div class="text">
                        <h4 class="dados_pessoa">
                            Telefone:
                        </h4>
                        <p>
                            <input id="telefone" class="input_atualizar" :value="item_pessoa.telefone">
                        </p>
                    </div>

                    <div class="status">
                        <h4>Status:</h4>
                        <div v-show="item_pessoa.status == true">Ativo</div>
                        <div v-show="item_pessoa.status == false">Inativo</div>
                    </div>
                </div>



                <div class="subtitulo">
                    Endereço
                </div>
                <div v-show="atualizar == false">
                    <div class="text">
                        <h4>Estado:</h4> {{ item_pessoa.estado }}
                    </div>
                    <div class="text">
                        <h4>Cidade:</h4> {{ item_pessoa.cidade }}
                    </div>
                    <div class="text">
                        <h4>Bairro:</h4> {{ item_pessoa.bairro }}
                    </div>
                    <div class="text">
                        <h4>Logradouro:</h4> {{ item_pessoa.logradouro }}
                    </div>
                    <div class="text">
                        <h4> Numero:</h4> {{ item_pessoa.numero }}
                    </div>
                    <div class="text">
                        <h4>Complemento:</h4> {{ item_pessoa.complemento }}
                    </div>
                </div>


                <div v-show="atualizar == true">
                    <div class="text">
                        <h4>Estado:</h4> <input class="input_atualizar" id="estado" :value="item_pessoa.estado">
                    </div>
                    <div class="text">
                        <h4>Cidade:</h4> <input class="input_atualizar" id="cidade" :value="item_pessoa.cidade">
                    </div>
                    <div class="text">
                        <h4>Bairro:</h4> <input class="input_atualizar" id="bairro" :value="item_pessoa.bairro">
                    </div>
                    <div class="text">
                        <h4>Logradouro:</h4> <input class="input_atualizar" id="logradouro"
                            :value="item_pessoa.logradouro">
                    </div>
                    <div class="text">
                        <h4> Numero:</h4> <input id="numero" class="input_atualizar" :value="item_pessoa.numero">
                    </div>
                    <div class="text">
                        <h4>Complemento:</h4> <input id="complemento" class="input_atualizar"
                            :value="item_pessoa.complemento">
                    </div>


                </div>

                <button class="salvar" @click="salvar_atualizacao(item_pessoa.id)" v-show="atualizar == true">Salvar
                    Dados</button>



            </div>


            <button class="sair" @click="sair" v-show="acesso == true && viewpessoa == false">Sair</button>

            <p class="msg_erro" v-show="resposta_atualizacao"> {{ resposta_atualizacao }}</p>

            <div class="atualizar_deletar">

                <button class="atualizar" @click="atualizando(item_pessoa)"
                    v-show="viewpessoa == true">Atualizar</button>
                <button class="deletar" @click="acesso = true, deletar_no_banco(item_pessoa)"
                    v-show="viewpessoa == true && adm == true">Deletar</button>
            </div>

            <button class="sair"
                @click="acesso = true, viewpessoa = false, imagem = '', atualizar = false, resposta_atualizacao = '', iniciar()"
                v-show="viewpessoa == true">Voltar</button>




        </div>

    </div>
</template>

<style scoped>
@media (max-width: 720px) {

    .corpo {
        min-height: 100vh;
        width: 100%;
        height: auto;
    }

    .form_pesquisar {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px;
    }

    .form_login {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: (#00000085);
        box-shadow: 0px 0px 10px 0px;
        border-radius: 10px;
        width: 80%;
        margin-bottom: 45%;
        margin-top: 10%;
        height: 30rem;
        padding-top: 10%;
    }

    .input_pesquisar {
        width: 100%;
        padding: 8px;
        border-radius: 5px 0px 0px 5px;
        outline: none;
        border: none;
        box-shadow: #0055a5 0px 0px 3px 0px;
    }

    .btn_pesquisar {
        border-radius: 0px 5px 5px 0px;
        background-color: #0055a5;
        color: #f3f3f3;
        padding: 8px;
        outline: none;
        border: none;
        box-shadow: #0055a5 0px 0px 3px 0px;
        cursor: pointer
    }

    .btn_pesquisar2 {
        border-radius: 5px;
        background-color: #0055a5;
        color: #f3f3f3;
        padding: 8px 15px;
        outline: none;
        border: none;
        box-shadow: #0055a5 0px 0px 3px 0px;
        margin-left: 5px;
    }

    .btn_pesquisar3 {
        width: 100%;
        background-color: #007e2a;
        color: #f3f3f3;
        padding: 8px;
        outline: none;
        border: none;
        box-shadow: #000000 0px 0px 3px 0px;
        letter-spacing: 5px;
        margin-bottom: 20px;
    }

    .dadospessoais {
        width: 80%;
        height: 40%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .dados {
        width: 80%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 15px;
        font-size: 1.2rem;
    }

    .section {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
    }

    .input {
        width: 100%;
        height: 2rem;
        text-align: center;
        margin: 2px 0px 10px 0px;
        border-radius: 5px;
        border: none;
        outline: none;
        font-size: 1rem;
        padding: 20px;
    }

    .titulo {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 30px;
        font-size: 1rem;
        font-weight: bold;
        color: #ffffff;
        letter-spacing: 3px;
        text-shadow: #00ffea 0px 0px 15px;
    }

    .titulo2 {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        padding: 10px;
        font-size: 1.2rem;
        font-weight: bold;
        color: #000000;
        letter-spacing: 1px;
    }

    .titulo3 {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 0px 5px;
        font-size: 1.2rem;
        font-weight: bold;
        color: #000000;
        letter-spacing: 1px;
        gap: 5px;
        margin-top: 10px;
    }

    label {
        color: #ffffff;
    }

    .entrar {
        padding: 15px;
        width: 65%;
        font-size: 1rem;
        letter-spacing: 2px;
        border-radius: 10px;
        border: none;
        background-color: #0055a5;
        color: #ffffff;
        transition: 0.5s;
        font-weight: bold;
        margin-top: 5%;
        margin-bottom: 10%;
    }

    .sair {
        padding: 15px;
        width: 90%;
        font-size: 1rem;
        letter-spacing: 2px;
        border-radius: 10px;
        border: none;
        background-color: #0055a5;
        color: #ffffff;
        transition: 0.5s;
        font-weight: bold;
        margin-top: 5%;
        margin-bottom: 30%;
    }

    .atualizar {
        padding: 15px;
        width: 40%;
        font-size: 1rem;
        letter-spacing: 2px;
        border-radius: 5px;
        border: 1px solid #000000;
        background-color: #ffffff;
        color: #0e6d2a;
        transition: 0.5s;
        font-weight: bold;
        margin-top: 5%;
        margin-bottom: 0%;
    }

    .salvar {
        padding: 15px;
        width: 45%;
        font-size: 1rem;
        letter-spacing: 2px;
        border-radius: 10px;
        background-color: #0e6d2a;
        color: #fff;
        transition: 0.5s;
        font-weight: bold;
        margin-top: 5%;
        margin-bottom: 0%;
    }

    .deletar {
        padding: 15px;
        width: 40%;
        font-size: 1rem;
        letter-spacing: 2px;
        border-radius: 5px;
        border: 1px solid #000000;
        background-color: #ff0000;
        color: #ffffff;
        transition: 0.5s;
        font-weight: bold;
        margin-top: 5%;
        margin-bottom: 0%;
    }

    .atualizar_deletar {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        gap: 10px;
    }

    ul {
        width: 95%;
        min-height: 50vh;
        background-color: #f3f3f3;
        border-radius: 10px;
        margin-top: 10px;
        box-shadow: 0px 0px 10px 0px;
    }


    li {
        display: flex;
        justify-content: center;
        width: 100%;
        cursor: pointer;
        transition: 0.5s;
    }

    li:hover {
        background-color: rgb(2, 247, 165);
    }

    .card {
        width: 100%;
        display: flex;
        flex-direction: row;
        padding: 5px 0px;
        align-items: center;
        justify-content: baseline;
        border-bottom: 0.02rem solid #0000002a;
    }

    .data {
        width: 10%;
        margin-right: 10%;
        font-size: 0.7rem;

    }

    .nome {
        width: 50%;
        display: flex;
        align-items: baseline;
        justify-content: baseline;
        font-size: 0.7rem;
        margin-left: 2%;
    }

    .idade {
        width: 2%;
        margin-right: 2%;
        margin-left: 2%;
        font-size: 0.7rem;
    }

    .telefone {
        width: 30%;
        font-size: 0.7rem;
        text-align: center;
    }

    .subs {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: baseline;
        padding: 10px 0px;
        font-size: 1rem;
        border-bottom: 1px solid #000;
    }

    .subdata {
        margin-left: 25%;
        width: 0%;
    }

    .subnome {
        margin-left: 2%;
        width: 0%;
    }

    .subidade {
        margin-left: 42%;
        width: 0%;
    }

    .subtelefone {
        margin-left: 15%;
        width: 0%;
    }


    .pessoa {
        width: 90%;
        height: auto;
        background-color: #b8fddb;
        padding: 10px;
        border-radius: 10px;
        box-shadow: 0px 0px 10px 0px;
        display: flex;
        flex-direction: column;
        align-items: baseline;
        justify-content: baseline;
    }


    .text {
        font-size: 0.8rem;
        padding: 5px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        letter-spacing: 1px;
    }

    .text_nome {
        font-size: 0.8rem;
        padding: 5px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 10px;
        letter-spacing: 1px;
    }

    .status {
        font-size: 0.8rem;
        padding: 5px;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 20px;
    }

    .subtitulo {
        width: 93%;
        font-size: 1rem;
        padding: 3%;
        margin: 3%;
        letter-spacing: 5px;
        border: 2px solid #8cdabc;
        border-radius: 8px;
        color: #211355;
        text-align: center;
        font-weight: bold;
    }

    .image {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    .foto {
        max-width: 100px;
        height: auto;
        border-radius: 10px;
        margin: 10px 0px;
    }

    .carregando {
        background-color: #000000;
        color: #f3f3f3;
        font-size: 1rem;
        padding: 5px;
        text-align: center;
        border-radius: 5px;
        margin: 0px 0px;
        width: 100%;
    }

    .msg_erro {
        margin: 5px;
        padding: 10px;
        margin-bottom: 20px;
        background-color: #b8fddb;
        color: brown;
        border-radius: 10px;
        font-size: 1rem;
    }

    .btnfiltro {
        padding: 1px;
        width: 30%;
        border-radius: 5px;
        border: none;
        background-color: #5289c7;
    }

    .btn {
        padding: 5px;
        width: 100%;
        font-size: 1rem;
        border: none;
        color: #f3f3f3;
        font-weight: bold;
        text-align: center;
    }

    .grupo {
        width: 90%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 5px;
        padding: 10px;
        gap: 5px;
        background-color: #1b2c3f;
        border-radius: 5px;
    }

    .filtros {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

    .mesbox {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border: 2 solid #00ffea;
        margin: 10px 0px 10px 0px;
    }

    .meses {
        font-size: 1rem;
        padding: 8px 25px;
    }

    .titulo_mes {
        color: #ffffff;
        letter-spacing: 1.4px;
        margin-bottom: 5px;
    }

    .numpessoas {
        color: #000000;
        margin-top: 10px;
    }

    .faixa {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-top: 5px;
        width: 80%;
    }

    .input_faixa {
        width: 80%;
        height: 30px;
        border-radius: 10px;
        text-align: center;
        border: 1px solid #000000;
    }

    .inputs {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 5px;
    }

    .faixa_total {
        width: 90%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        border-radius: 10px;
        padding: 10px;
        gap: 5px;
    }

    .titulo_faixa {
        font-size: 0.8rem;
        margin-top: 5px;
    }

    .btn_faixa {
        width: 100%;
        height: 40px;
        border-radius: 10px;
        border: none;
        background-color: #5289c7;
    }

    .nome_titulo {
        max-width: 80%;
        font-size: 1rem;
        text-align: center;
        font-weight: bold;
        margin: 0px 10px;
    }

    .borda {
        width: 80%;
        border: 2px solid #000000;
    }

    .router {
        width: 100%;
        text-decoration: none;
        color: #000;
        display: flex;
        align-items: center;
        justify-content: center;

    }

    .input_atualizar {
        border-radius: 3px;
        border: none;
        color: #000;
        width: 80%;
        height: 25px;
        padding: 2%;
    }

    img {
        width: 60%;
    }

    .imgDiv {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-around;
        padding: 5px;
        border-bottom: 2px solid #fff;

    }

    .menu {
        border: 0.1rem solid #fff;
        margin: 5px;
        border-radius: 5px;
    }

    .icone_menu {
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .icone_menu p {
        color: #fff;
        font-size: 0.7rem;
    }

    .local_imagem {
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 50%;
        padding: 0%;
        margin: 0%;
    }

    .remover_imagem {
        background-color: #ff0000;
        color: #f3f3f3;
        padding: 1%;
        font-size: 0.7rem;
        border: 1px solid #000;
        border-radius: 5px;
    }

    .adicionar_imagem {
        background-color: #018012;
        color: #f3f3f3;
        padding: 1%;
        font-size: 0.7rem;
        border: 1px solid #000;
        border-radius: 5px;
    }

    .salvar {
        width: 93%;
        font-size: 0.9rem;
        border: 1px solid #f3f3f3;


    }

    .btns_imagens {
        display: flex;
        flex-direction: row;
    }

    .aniversariantes {
        display: flex;
        flex-direction: column;
        width: 95%;
        height: auto;
        background-color: #c5cdd4;
        border-radius: 10px;
        gap: 8px;
        padding: 2%;
        box-shadow: #0055a5 0px 0px 10px 0px;
        margin: 4% 0%;
    }

    .titulo_niver {
        font-size: 0.8rem;
        text-align: center;
        font-weight: bold;
        margin-bottom: 3%;
        color: #0055a5;
    }

    .niverdodia {
        font-size: 0.6rem;
        text-align: center;
        color: #0055a5;
    }

    .menu_sair {
        width: 10%;
        padding: 2%;
        background-color: #fff;
        color: #0055a5;
        border: none;
        border-radius: 5px;
        margin-right: 1%;
        margin-left: 5%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .menu {
        width: 10%;
        border: none;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .logoinicial {
        width: 30%;
        padding: 2%;
        background-color: #f3f3f3;
        border-radius: 10%;
    }

    .niver_btn_ativar {
        background-color: #0055a5;
        color: #fff;
        border: solid  #0055a5;        
    }

}

@media (min-width: 721px) {

    .corpo {
        min-height: 100vh;
        width: 100%;
        height: auto;
    }

    .form_pesquisar {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px;
    }

    .form_login {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: (#00000085);
        box-shadow: 0px 0px 10px 0px;
        border-radius: 10px;
        width: 50%;
        margin-bottom: 5%;
        margin-top: 0%;
        height: 20rem;
        padding-top: 0%;
    }

    .input_pesquisar {
        width: 100%;
        padding: 8px;
        border-radius: 5px 0px 0px 5px;
        outline: none;
        border: none;
        box-shadow: #0055a5 0px 0px 3px 0px;
    }

    .btn_pesquisar {
        border-radius: 0px 5px 5px 0px;
        background-color: #0055a5;
        color: #f3f3f3;
        padding: 8px;
        outline: none;
        border: none;
        box-shadow: #0055a5 0px 0px 3px 0px;
    }

    .btn_pesquisar2 {
        border-radius: 5px;
        background-color: #0055a5;
        color: #f3f3f3;
        padding: 8px 15px;
        outline: none;
        border: none;
        box-shadow: #0055a5 0px 0px 3px 0px;
        margin-left: 5px;
    }

    .btn_pesquisar3 {
        width: 100%;
        background-color: #007e2a;
        color: #f3f3f3;
        padding: 8px;
        outline: none;
        border: none;
        box-shadow: #000000 0px 0px 3px 0px;
        letter-spacing: 5px;
        margin-bottom: 20px;
    }

    .dadospessoais {
        width: 80%;
        height: 40%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .dados {
        width: 80%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 15px;
        font-size: 1.2rem;
    }

    .section {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
    }

    .input {
        width: 100%;
        height: 2rem;
        text-align: center;
        margin: 2px 0px 10px 0px;
        border-radius: 5px;
        border: none;
        outline: none;
        font-size: 1rem;
        padding: 20px;
    }

    .titulo {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 30px;
        font-size: 1rem;
        font-weight: bold;
        color: #ffffff;
        letter-spacing: 3px;
        text-shadow: #00ffea 0px 0px 15px;
    }

    .titulo2 {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        padding: 10px;
        font-size: 1.2rem;
        font-weight: bold;
        color: #000000;
        letter-spacing: 1px;
    }

    .titulo3 {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 0px 5px;
        font-size: 1.2rem;
        font-weight: bold;
        color: #000000;
        letter-spacing: 1px;
        gap: 5px;
        margin-top: 10px;
    }

    label {
        color: #ffffff;
    }

    .entrar {
        padding: 15px;
        width: 65%;
        font-size: 1rem;
        letter-spacing: 2px;
        border-radius: 10px;
        border: none;
        background-color: #0055a5;
        color: #ffffff;
        transition: 0.5s;
        font-weight: bold;
        margin-top: 5%;
        margin-bottom: 10%;
    }

    .sair {
        padding: 15px;
        width: 90%;
        font-size: 1rem;
        letter-spacing: 2px;
        border-radius: 10px;
        border: none;
        background-color: #0055a5;
        color: #ffffff;
        transition: 0.5s;
        font-weight: bold;
        margin-top: 5%;
        margin-bottom: 30%;
    }

    .atualizar {
        padding: 15px;
        width: 40%;
        font-size: 1rem;
        letter-spacing: 2px;
        border-radius: 5px;
        border: 1px solid #000000;
        background-color: #ffffff;
        color: #0e6d2a;
        transition: 0.5s;
        font-weight: bold;
        margin-top: 5%;
        margin-bottom: 0%;
    }

    .salvar {
        padding: 15px;
        width: 45%;
        font-size: 1rem;
        letter-spacing: 2px;
        border-radius: 10px;
        background-color: #0e6d2a;
        color: #fff;
        transition: 0.5s;
        font-weight: bold;
        margin-top: 5%;
        margin-bottom: 0%;
    }

    .deletar {
        padding: 15px;
        width: 40%;
        font-size: 1rem;
        letter-spacing: 2px;
        border-radius: 5px;
        border: 1px solid #000000;
        background-color: #ff0000;
        color: #ffffff;
        transition: 0.5s;
        font-weight: bold;
        margin-top: 5%;
        margin-bottom: 0%;
    }

    .atualizar_deletar {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        gap: 10px;
    }

    ul {
        width: 95%;
        min-height: 50vh;
        background-color: #f3f3f3;
        border-radius: 10px;
        margin-top: 10px;
        box-shadow: 0px 0px 10px 0px;
    }


    li {
        display: flex;
        justify-content: center;
        width: 100%;
        cursor: pointer;
        transition: 0.5s;
    }

    li:hover {
        background-color: rgb(2, 247, 165);
    }

    .card {
        width: 100%;
        display: flex;
        flex-direction: row;
        padding: 5px 0px;
        align-items: center;
        justify-content: baseline;
        border-bottom: 0.02rem solid #0000002a;
    }

    .data {
        width: 10%;
        margin-right: 10%;
        font-size: 0.7rem;

    }

    .nome {
        width: 50%;
        display: flex;
        align-items: baseline;
        justify-content: baseline;
        font-size: 0.7rem;
        margin-left: 2%;
    }

    .idade {
        width: 2%;
        margin-right: 2%;
        margin-left: 2%;
        font-size: 0.7rem;
    }

    .telefone {
        width: 30%;
        font-size: 0.7rem;
        text-align: center;
    }

    .subs {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: baseline;
        padding: 10px 0px;
        font-size: 1rem;
        border-bottom: 1px solid #000;
    }

    .subdata {
        margin-left: 25%;
        width: 0%;
    }

    .subnome {
        margin-left: 2%;
        width: 0%;
    }

    .subidade {
        margin-left: 42%;
        width: 0%;
    }

    .subtelefone {
        margin-left: 15%;
        width: 0%;
    }


    .pessoa {
        width: 90%;
        height: auto;
        background-color: #b8fddb;
        padding: 10px;
        border-radius: 10px;
        box-shadow: 0px 0px 10px 0px;
        display: flex;
        flex-direction: column;
        align-items: baseline;
        justify-content: baseline;
    }


    .text {
        font-size: 0.8rem;
        padding: 5px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        letter-spacing: 1px;
    }

    .text_nome {
        font-size: 0.8rem;
        padding: 5px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 10px;
        letter-spacing: 1px;
    }

    .status {
        font-size: 0.8rem;
        padding: 5px;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 20px;
    }

    .subtitulo {
        width: 93%;
        font-size: 1rem;
        padding: 3%;
        margin: 3%;
        letter-spacing: 5px;
        border: 2px solid #8cdabc;
        border-radius: 8px;
        color: #211355;
        text-align: center;
        font-weight: bold;
    }

    .image {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    .foto {
        max-width: 100px;
        height: auto;
        border-radius: 10px;
        margin: 10px 0px;
    }

    .carregando {
        background-color: #000000;
        color: #f3f3f3;
        font-size: 1rem;
        padding: 5px;
        text-align: center;
        border-radius: 5px;
        margin: 0px 0px;
        width: 100%;
    }

    .msg_erro {
        margin: 5px;
        padding: 10px;
        margin-bottom: 20px;
        background-color: #b8fddb;
        color: brown;
        border-radius: 10px;
        font-size: 1rem;
    }

    .btnfiltro {
        padding: 1px;
        width: 30%;
        border-radius: 5px;
        border: none;
        background-color: #5289c7;
    }

    .btn {
        padding: 5px;
        width: 100%;
        font-size: 1rem;
        border: none;
        color: #f3f3f3;
        font-weight: bold;
        text-align: center;
        cursor: pointer;
    }

    .grupo {
        width: 90%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 5px;
        padding: 10px;
        gap: 5px;
        background-color: #1b2c3f;
        border-radius: 5px;
    }

    .filtros {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

    .mesbox {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border: 2 solid #00ffea;
        margin: 10px 0px 10px 0px;
    }

    .meses {
        font-size: 1rem;
        padding: 8px 25px;
    }

    .titulo_mes {
        color: #ffffff;
        letter-spacing: 1.4px;
        margin-bottom: 5px;
    }

    .numpessoas {
        color: #000000;
        margin-top: 10px;
    }

    .faixa {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-top: 5px;
        width: 80%;
    }

    .input_faixa {
        width: 80%;
        height: 30px;
        border-radius: 10px;
        text-align: center;
        border: 1px solid #000000;
    }

    .inputs {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 5px;
    }

    .faixa_total {
        width: 90%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        border-radius: 10px;
        padding: 10px;
        gap: 5px;
    }

    .titulo_faixa {
        font-size: 0.8rem;
        margin-top: 5px;
    }

    .btn_faixa {
        width: 100%;
        height: 40px;
        border-radius: 10px;
        border: none;
        background-color: #5289c7;
    }

    .nome_titulo {
        max-width: 80%;
        font-size: 1rem;
        text-align: center;
        font-weight: bold;
        margin: 0px 10px;
    }

    .borda {
        width: 80%;
        border: 2px solid #000000;
    }

    .router {
        width: 100%;
        text-decoration: none;
        color: #000;
        display: flex;
        align-items: center;
        justify-content: center;

    }

    .input_atualizar {
        border-radius: 3px;
        border: none;
        color: #000;
        width: 80%;
        height: 25px;
        padding: 2%;
    }

    img {
        width: 60%;
    }

    .imgDiv {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-around;
        padding: 5px;
        border-bottom: 2px solid #fff;

    }

    .menu {
        border: 0.1rem solid #fff;
        margin: 5px;
        border-radius: 5px;
    }

    .icone_menu {
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .icone_menu p {
        color: #fff;
        font-size: 0.7rem;
    }

    .local_imagem {
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 20%;
        padding: 0%;
        margin: 0%;
    }

    .remover_imagem {
        background-color: #ff0000;
        color: #f3f3f3;
        padding: 1%;
        font-size: 0.7rem;
        border: 1px solid #000;
        border-radius: 5px;
    }

    .adicionar_imagem {
        background-color: #018012;
        color: #f3f3f3;
        padding: 1%;
        font-size: 0.7rem;
        border: 1px solid #000;
        border-radius: 5px;
    }

    .salvar {
        width: 93%;
        font-size: 0.9rem;
        border: 1px solid #f3f3f3;


    }

    .btns_imagens {
        display: flex;
        flex-direction: row;
    }

    .aniversariantes {
        display: flex;
        flex-direction: column;
        width: 95%;
        height: auto;
        background-color: #c5cdd4;
        border-radius: 10px;
        gap: 8px;
        padding: 2%;
        box-shadow: #0055a5 0px 0px 10px 0px;
        margin: 4% 0%;
    }

    .titulo_niver {
        font-size: 0.8rem;
        text-align: center;
        font-weight: bold;
        margin-bottom: 3%;
        color: #0055a5;
    }

    .niverdodia {
        font-size: 0.6rem;
        text-align: center;
        color: #0055a5;
    }

    .menu_sair {
        width: 5%;
        padding: 1%;
        background-color: #fff;
        color: #0055a5;
        border: none;
        border-radius: 5px;
        margin-right: 1%;
        margin-left: 5%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    .menu {
        width: 5%;
        border: none;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .logoinicial {
        width: 30%;
        padding: 2%;
        background-color: #f3f3f3;
        border-radius: 10%;
    }

    .imagem_menu {
        width: 30%;
    }

    .niver_btn_ativar {
        background-color: #0055a5;
        color: #fff;
        border: solid  #0055a5;     }

}
</style>